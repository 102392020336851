import { createGlobalStyle } from "styled-components";
import "normalize.css/normalize.css";

const GlobalStyle = createGlobalStyle`
  /* Import Fonts */
  @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;700&display=swap');

  /* Root Variables */
  :root {
    /* Color Variables */
    --hhb-primary:  #0E94B4;
    --hhb-secondary: #C0D400;
    --hhb-white: #ffffff;
    --hhb-grey: #888b8d;
    --hhb-orange: #ff671f;
    --hhb-red: #a4343a;
    --hhb-darkblue: #002f6c;
    --hhb-green: #43b02a;
    --hhb-yellow: #ffd100;
    --hhb-offwhite: #fffff0;

    /* Common Colors */
    --primary-color: var(--hhb-primary);
    --secondary-color: var(--hhb-white);
    --accent-color: var(--hhb-yellow);
    --text-color: #333333;
    --background-color: #f7f7f7;

    /* Font Variables */
    --font-title: 'Work Sans', sans-serif;
    --font-size-title: 48px;
    --font-size-subtitle: 40px;
    --font-size-heading: 32px;
    --font-size-subheading: 24px;
    --font-size-section-header: 20px;
    --font-size-body: 16px;
    
    /* Layout Variables */
    --line-height: 1.75;
    --paragraph-margin: 0 0 20px;
    --heading-margin: 0 0 20px 0;
    --container-padding: 20px;
    --iframe-width: 100%;
    --iframe-height: 315px;
  }

  /* Reset and Base Styles */
  body, html {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  body {
    font-family: var(--font-title);
    color: var(--text-color);
    background-color: var(--background-color);
    line-height: var(--line-height);
    overflow-x: hidden; /* Prevent horizontal scroll */
    display: flex;
    flex-direction: column;
    min-height: 100vh;
     overflow-x: hidden;
  }

  /* Headings */
  h1, h2, h3, h4, h5 {
    font-family: var(--font-title);
    font-weight: 700;
    margin: var(--heading-margin);
    text-align: center;
  }

  h1 { font-size: var(--font-size-title); }
  h2 { font-size: var(--font-size-subtitle); }
  h3 { font-size: var(--font-size-heading); }
  h4 { font-size: var(--font-size-subheading); font-weight: 500; }
  h5 { font-size: var(--font-size-section-header); font-weight: 500; }

  /* Paragraphs */
  p {
    font-size: var(--font-size-body);
    font-weight: 400;
    line-height: var(--line-height);
    margin: var(--paragraph-margin);
    word-wrap: break-word; /* Ensures paragraphs wrap properly */
    max-width: 100%; /* Ensure paragraphs do not overflow */
  }

  /* Links */
  a {
    color: var(--primary-color);
    text-decoration: none;
  }

  a:hover {
   
  }

  /* Buttons */
  button {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    font-family: var(--font-title);
  }

  button:hover {
    background-color: var(--accent-color);
  }

  /* Utility Classes */
  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: var(--container-padding);
    box-sizing: border-box; 
    overflow-x: hidden;
    flex-grow: 1; 
  }

  .text-center {
    text-align: center;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  /* Footer Styles */
  .footer {
    background: #f8f9fa;
    padding: 20px;
    text-align: center;
    width: 100%;
    flex-shrink: 0; /* Ensure the footer doesn't shrink */
  }

  .footer p {
    margin: 0;
  }

  /* Section Headers */
  .section-header {
    text-align: center;
    padding: 10px;
    margin: 40px 0;
    color: var(--hhb-white);
    font-weight: 700;
  }

  .section-header.primary { background-color: var(--hhb-primary); }
  .section-header.secondary { background-color: var(--hhb-secondary); }
  .section-header.green { background-color: var(--hhb-green); }
  .section-header.orange { background-color: var(--hhb-orange); }
  .section-header.red { background-color: var(--hhb-red); }
  .section-header.darkblue { background-color: var(--hhb-darkblue); }
  .section-header.yellow { background-color: var(--hhb-yellow); }

  /* Responsive Iframe */
  .iframe-container {
    text-align: center;
    margin: 20px 0;
    height: 100%;
  }

  .iframe-container iframe {
    width: var(--iframe-width);
    height: var(--iframe-height);
  }

  /* Image Styles */
  .image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 10px;
  }

  .image-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .image-container div {
    flex: 1;
    min-width: 300px;
    margin: 10px;
  }

  /* Projects */
  .projects-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
  }

  .project {
    flex: 1;
    min-width: 300px;
    text-align: center;
  }

  .project-image {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .project-title {
    font-size: var(--font-size-heading);
    margin-bottom: 10px;
    color: var(--primary-color);
  }

  .project-description {
    font-size: var(--font-size-body);
    line-height: var(--line-height);
    margin-bottom: 20px;
  }

  /* Buttons */
  .button {
    display: inline-block;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-size: var(--font-size-body);
    font-family: var(--font-title);
  }

  .button:hover {
    background-color: var(--accent-color);
  }

  /* Icons */
  .icon-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }

  .icon-image {
    margin-bottom: 10px;
  }

  /* Grid Layouts */
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }

  .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  /* Forms */

/* Forms */
form {
  max-width: 800px; 
  margin: 0 auto; 
  padding: 20px;
  background-color: var(--hhb-white);
}

label {
  font-size: var(--font-size-body);
  font-weight: 500;
  margin-bottom: 8px;
  color: var(--text-color);
  display: block;
}

input,
textarea {
  padding: 10px;
  font-size: var(--font-size-body);
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: var(--primary-color);
  }
}

textarea {
  resize: vertical;
  height: 100px;
}

button {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 10px 20px;
  font-size: var(--font-size-body);
  width: 100%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--accent-color);
  }
}

  /* Priority Items */
  .priority-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
  }

  /* Headshots */
  .headshot {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }

  /* Info Section */
  .info h3 {
    margin: 10px 0 5px;
  }

  .info p {
    margin: 5px 0;
  }

  /* Links */
  .link {
    color: var(--primary-color);
    text-decoration: none;
  }

  .link:hover {
    text-decoration: underline;
  }

  /* Responsive Adjustments */
  @media (max-width: 768px) {
    /* For Tablets */
    h1 { font-size: 36px; }
    h2 { font-size: 32px; }
    h3 { font-size: 28px; }
    h4 { font-size: 24px; }
    h5 { font-size: 18px; }
    .container { padding: 10px; }
    .image-container { flex-direction: column; }
    .projects-container { flex-direction: column; }
  }

  @media (max-width: 480px) {
    /* For Mobile Phones */
    h1 { font-size: 28px; }
    h2 { font-size: 24px; }
    h3 { font-size: 20px; }
    h4 { font-size: 18px; }
    h5 { font-size: 16px; }
    .container { padding: 5px; }
    .image-container div { min-width: 100%; }
    .projects-container { flex-direction: column; }
  }
`;

export default GlobalStyle;
